* {
    box-sizing: border-box;
}

html {
    font-size: 16px;
}

body {
    margin: 0;
    font-family: "Roboto";
    background-color: #f5f6fa;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
// ::-webkit-scrollbar-track {
//   background: rgba(0, 0, 0, 0.1);
// }

::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
}
